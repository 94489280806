export function roundMarketPrice(price) {
  if (price === null || typeof price === 'undefined') {
    price = 0;
  }

  const decimalPlaces = price.toString().split('.')[1]?.length || 0;

  const fixedSymbols = decimalPlaces > 3 || decimalPlaces === 0 ? 5 : 4;
  return parseFloat(price.toFixed(fixedSymbols));
}
