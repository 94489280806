<script>
export default {
  name: 'DatePeriodSelect',
  props: {
    initalPeriod: {
      type: String,
      default: 'week',
    },
    modelValue: {
      type: Array,
    },
  },
  emits: ['update:modelValue'],
  computed: {
    period() {
      return this.$moment(this.modelValue[1]).lang('en').diff(this.$moment(this.modelValue[0]), 'days');
    },
  },
  methods: {
    select(period) {
      if (this.period === period) {
        return;
      }

      this.$emit('update:modelValue', [
        this.$moment(new Date()).lang('en').startOf('day').subtract(period, 'd').format(),
        this.$moment(new Date()).lang('en').startOf('day').startOf('day').format(),
      ]);
    },
  },
};
</script>

<template>
  <div class="toolbar d-flex flex-wrap gap-2 justify-content-center align-items-center">
    <!-- <i class="bx bx-calendar text-muted font-size-16"></i> -->

    <button
      type="button"
      class="btn btn-sm"
      :class="{
        'btn-light': period === 7,
        'text-muted': period !== 7,
      }"
      @click="select(7)"
    >
      {{ $t('Common.Week') }}
    </button>

    <button
      type="button"
      class="btn btn-sm"
      :class="{
        'btn-light': period === 30,
        'text-muted': period !== 30,
      }"
      @click="select(30)"
    >
      {{ $t('Common.Month') }}
    </button>

    <button
      type="button"
      class="btn btn-sm"
      :class="{
        'btn-light': period === 365,
        'text-muted': period !== 365,
      }"
      @click="select(365)"
    >
      {{ $t('Common.Year') }}
    </button>
  </div>
</template>
