<script>
import { ErrorMessage, Field, Form } from 'vee-validate';
import { validationMixin } from '@/mixins/form/validation';

export default {
  name: 'AddDemoDepositPopup',
  mixins: [validationMixin],
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  props: {
    account: {
      type: Object,
    },
  },
  data() {
    return {
      form: {
        amount: null,
      },
      loading: {
        submit: false,
      },
      modalShow: true,
      defaultAmounts: [250, 500, 1000],
    };
  },
  methods: {
    submit(values, actions) {
      this.loading.submit = true;

      this.axios
        .post(`accounts/${this.account.id}/demo/deposit`, this.form)
        .then(() => {
          this.$notify({
            type: 'success',
            text: this.$t('Deposit.SuccessDemoDeposit'),
          });
          this.modalShow = false;
        })
        .catch(({ response }) => {
          this.handleResponseErrors(response, { values, actions });
        })
        .finally(() => {
          this.loading.submit = false;
        });
    },
  },
};
</script>

<template>
  <b-modal
    v-model="modalShow"
    id="add-demo-deposit-popup"
    hide-footer
    centered
    header-class="border-0"
    lazy
    @hidden="$emit('closeModal')"
  >
    <b-overlay :show="loading.submit" spinner-variant="primary" rounded="sm">
      <Form @submit="submit">
        <b-form-group class="mb-3" :label="$t('OpenAccount.NameAccount')">
          <b-form-input :modelValue="account.name" disabled type="text" :placeholder="$t('OpenAccount.Name')" />
        </b-form-group>

        <b-form-group class="mb-3" :label="$t('OpenAccount.Leverage')">
          <b-form-select :modelValue="account.accountLeverage" :options="[account?.accountLeverage]" disabled />
        </b-form-group>

        <b-form-group class="mb-3" :label="$t('OpenAccount.Currency')">
          <b-form-select :modelValue="account.currency.key" :options="[account.currency.key]" disabled />
        </b-form-group>

        <Field
          name="amount"
          rules="required|min:1"
          :label="$t('Deposit.CardForm.AmountOfPayment')"
          v-slot="{ errors, field, handleChange }"
        >
          <div class="row align-items-center mb-3">
            <div class="col-md-6">
              <b-form-group class="mb-3 text-muted" :label="$t('Deposit.CardForm.AmountOfPayment')" label-for="amount">
                <b-form-input
                  id="amount"
                  v-model="form.amount"
                  type="text"
                  :placeholder="$t('Deposit.CardForm.AmountOfPayment')"
                  v-maska="{ mask: 'XZ*', tokens: { X: { pattern: /[1-9]/ }, Z: { pattern: /[0-9]/ } } }"
                  :class="{ 'is-invalid': errors.length }"
                  v-bind="field"
                />

                <ErrorMessage name="amount" class="invalid-feedback" />
              </b-form-group>
            </div>

            <div class="col-md-6">
              <button
                v-for="amount in defaultAmounts"
                :key="amount"
                type="button"
                class="btn btn-soft-secondary btn-sm btn-rounded me-2 mt-1 mb-1"
                @click="
                  form.amount = amount;
                  handleChange(amount);
                "
              >
                {{ amount }} USD
              </button>
            </div>
          </div>
        </Field>

        <div class="d-flex justify-content-end">
          <button type="submit" class="btn btn-dark btn-sm w-lg">{{ $t('Common.Continue') }}</button>
        </div>
      </Form>
    </b-overlay>
  </b-modal>
</template>
