<script>
import DatePeriodSelect from '@/components/common/form/DatePeriodSelect';
import { roundMarketPrice } from '@/helpers/trading';
import { mapGetters } from 'vuex';

export default {
  name: 'order-history',
  components: {
    DatePeriodSelect,
  },
  props: {
    title: {
      type: String,
      default: 'Order history',
    },
    accountId: {
      type: Number,
    },
  },
  data() {
    return {
      cursor: null,
      period: [
        this.$moment(new Date()).lang('en').startOf('day').subtract(7, 'd').format(),
        this.$moment(new Date()).lang('en').startOf('day').format(),
      ],
      filters: {
        created_from: this.$moment(new Date()).lang('en').startOf('day').subtract(7, 'd').format(),
        per_page: 10,
      },
      orders: [],
      loading: {
        orders: false,
      },
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountsMap']),
  },
  watch: {
    accountId() {
      this.fetchOrders();
    },
  },
  methods: {
    fetchOrders(nextPage = false) {
      this.loading.orders = true;

      const params = {
        ...this.filters,
        close: 1,
        created_from: this.period[0],
        account_id: this.accountId || null,
      };

      if (nextPage && this.cursor.next) {
        params.page = this.cursor.current + 1;
      }

      this.axios
        .get('/orders', { params })
        .then(({ data, cursor }) => {
          this.orders = nextPage ? [...this.orders, ...data] : data;
          this.cursor = cursor;
        })
        .finally(() => {
          this.loading.orders = false;
        })
        .then(() => {
          this.handleScroll();
        });
    },
    handleScroll() {
      if (this.loading.orders || !this.cursor.next) {
        return;
      }

      if (this.$refs['order-history-tab'].getBoundingClientRect().bottom < window.innerHeight) {
        this.fetchOrders(true);
      }
    },
    cutStr(str, len = 10) {
      const strTab = str.split('');
      strTab.length = len;
      return strTab.join('') + '...';
    },
    roundMarketPrice,
  },
  mounted() {
    this.fetchOrders();
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<template>
  <b-overlay :show="loading.orders" spinner-variant="primary" rounded="sm">
    <div class="card">
      <div class="card-body">
        <div class="order-history-heading d-sm-flex justify-content-between">
          <div>
            <h4 v-if="title" class="card-title">{{ title }}</h4>
          </div>

          <date-period-select v-model="period" class="mt-2 mt-sm-0" @update:modelValue="fetchOrders()" />
        </div>

        <div class="table-responsive">
          <table ref="order-history-tab" class="table table-bordered mb-0 mt-3 text-secondary">
            <thead>
              <tr>
                <th>{{ $t('Transaction.Symbol') }}</th>
                <th>{{ $t('Transaction.Number') }}</th>
                <th>{{ $t('Transaction.Type') }}</th>
                <th>{{ $t('Transaction.Volume') }}</th>
                <th>{{ $t('Transaction.OpenDate') }}</th>
                <th>{{ $t('Transaction.CloseDate') }}</th>
                <th>{{ $t('Transaction.OpenPrice') }}</th>
                <th>{{ $t('Transaction.ClosePrice') }}</th>
                <!-- <th>{{ $t('Transaction.Commission') }}</th> -->
                <th>{{ $t('Transaction.Profit') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order in orders" :key="order.id">
                <td>
                  <span class="fw-bold text-dark">{{ order.market.name }}</span>
                </td>
                <td data-placement="top" v-b-tooltip.hover :title="order.uuid">{{ cutStr(order.uuid, 8) }}</td>
                <td :class="[order.type === 0 ? 'text-success' : 'text-danger', 'fw-medium']">
                  {{ order.type === 0 ? $t('Transaction.Buy') : $t('Transaction.Sell') }}
                </td>
                <td>{{ order.volume }} {{ $t('Transaction.lots') }}</td>
                <td>
                  <div class="date">
                    {{ $moment(order.open_at).format('DD.MM.YYYY') }}
                  </div>
                  {{ $moment(order.open_at).format('HH:mm') }}
                </td>
                <td>
                  <template v-if="order.close_at">
                    <div class="date">
                      {{ $moment(order.close_at).format('DD.MM.YYYY') }}
                    </div>
                    {{ $moment(order.close_at).format('HH:mm') }}
                  </template>
                </td>
                <td class="text-dark fw-medium text-nowrap">
                  {{ order.open_price
                  }}{{
                    accountsMap[order.account_id]?.currency?.symbol || accountsMap[order.account_id]?.currency?.key
                  }}
                </td>
                <td class="text-dark fw-medium text-nowrap">
                  <span v-if="order.current_price"
                    >{{ order.current_price
                    }}{{
                      accountsMap[order.account_id]?.currency?.symbol || accountsMap[order.account_id]?.currency?.key
                    }}
                  </span>
                </td>
                <!-- <td class="fw-medium">$0.42</td>  -->
                <td
                  class="fw-medium text-nowrap"
                  :class="[
                    order.profit - order.fee > 0 ? 'text-success' : '',
                    order.profit - order.fee < 0 ? 'text-danger' : '',
                  ]"
                >
                  {{ roundMarketPrice(order.profit - order.fee) }}
                  {{ accountsMap[order.account_id]?.currency?.key || accountsMap[order.account_id]?.currency?.symbol }}
                </td>
              </tr>
              <tr v-if="!orders.length">
                <td colspan="9" class="table__no-records py-4">{{ $t('Accounts.NoOrders') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<style scoped lang="scss">
th {
  font-weight: 400;
  color: #b9b9c3;
  border: none;
}

td {
  border: none;
}

.date {
  font-weight: 500;
  color: #81828d;
}
</style>
