<script>
import DatePeriodSelect from '@/components/common/form/DatePeriodSelect';
import BtcIcon from '@/components/common/currency-icons/btc';
import EthIcon from '@/components/common/currency-icons/eth';
import CurrencyIcon from '@/components/common/currency-icons/currency';
import { mapGetters } from 'vuex';

export default {
  name: 'account-transfers',
  components: {
    DatePeriodSelect,
    BtcIcon,
    EthIcon,
    CurrencyIcon,
  },
  props: {
    title: {
      type: String,
      default: 'Transfers',
    },
    accountId: {
      type: Number,
    },
    hideLoadingOverlay: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      cursor: null,
      period: [
        this.$moment(new Date()).lang('en').startOf('day').subtract(7, 'd').format(),
        this.$moment(new Date()).lang('en').startOf('day').format(),
      ],
      filters: {
        created_from: this.$moment(new Date()).lang('en').startOf('day').subtract(7, 'd').format(),
        per_page: 10,
      },
      transfers: [],
      loading: {
        transfers: false,
      },
    };
  },
  computed: {
    ...mapGetters('accounts', ['accountsMap']),
  },
  watch: {
    accountId() {
      this.fetchTransfers();
    },
  },
  methods: {
    fetchTransfers(nextPage = false) {
      this.loading.transfers = true;

      const params = {
        ...this.filters,
        created_from: this.period[0],
        account_id: this.accountId || null,
      };

      if (nextPage && this.cursor.next) {
        params.cursor = this.cursor.next;
      }

      this.axios
        .get('/transactions', { params })
        .then(({ data, cursor }) => {
          this.transfers = nextPage ? [...this.transfers, ...data] : data;
          this.cursor = cursor;
        })
        .finally(() => {
          this.loading.transfers = false;
        });
    },

    handleScroll() {
      if (this.loading.transfers || !this.cursor.next) {
        return;
      }

      if (this.$refs['transfer-history-tab'].getBoundingClientRect().bottom < window.innerHeight) {
        this.fetchTransfers(true);
      }
    },
  },
  mounted() {
    this.fetchTransfers();
    window.addEventListener('scroll', this.handleScroll);
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<template>
  <b-overlay :show="loading.transfers && !hideLoadingOverlay" spinner-variant="primary" rounded="sm">
    <div class="card">
      <div class="card-body">
        <div class="account-transfers-heading d-sm-flex justify-content-between">
          <div>
            <h4 v-if="title" class="card-title">{{ title }}</h4>
          </div>

          <date-period-select v-model="period" @update:modelValue="fetchTransfers()" class="mt-2 mt-sm-0" />
        </div>

        <!-- <div class="row">
          <div class="col-xl-4 offset-xl-4">
            <div class="d-flex justify-content-evenly mb-4">
              <div>
                <div class="text-muted mb-1">{{ $t("Transaction.Swap") }}</div>
                <div>0.00 USD</div>
              </div>

              <div>
                <div class="text-muted mb-1">
                  {{ $t("Transaction.Commission") }}
                </div>
                <div>0.00 USD</div>
              </div>

              <div>
                <div class="text-muted mb-1">
                  {{ $t("Transaction.Profit") }}
                </div>
                <div>0.00 USD</div>
              </div>
            </div>
          </div>
        </div> -->

        <div class="table-responsive">
          <table ref="transfer-history-tab" class="table table-bordered mb-0 mt-3 text-secondary">
            <thead>
              <tr>
                <th>{{ $t('Accounts.Account') }}</th>
                <th>{{ $t('Transaction.Number') }}</th>
                <th>{{ $t('Transaction.Currency') }}</th>
                <th>{{ $t('Common.Date') }}</th>
                <th>{{ $t('Transaction.Operation') }}</th>
                <!-- <th>{{ $t('Transaction.WhereFrom') }}</th> -->
                <!-- <th>{{ $t('Transaction.WhereTo') }}</th> -->
                <th>{{ $t('Transaction.Amount') }}</th>
                <th>{{ $t('Transaction.Status') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="transfer in transfers" :key="transfer.id">
                <td>{{ transfer.account.name }}</td>
                <td scope="row">{{ transfer.id }}</td>
                <td>
                  <div class="d-flex align-items-center">
                    <div class="me-3">
                      <btc-icon v-if="transfer.currency?.key === 'BTC'" :size="32" />
                      <eth-icon v-else-if="transfer.currency?.key === 'ETH'" :size="32" />
                      <currency-icon v-else :symbol="transfer.currency?.symbol || transfer.currency?.key" :size="32" />
                    </div>
                    <h4 class="font-size-14 mb-0">
                      {{ transfer.currency?.key || transfer.currency?.symbol }}
                    </h4>
                  </div>
                </td>
                <td>
                  <div class="date"></div>
                  <h5 class="font-size-14 mb-1">{{ $moment(transfer.created_at).format('DD.MM.YYYY') }}</h5>
                  <div class="text-muted">{{ $moment(transfer.created_at).format('HH:mm') }}</div>
                </td>
                <td>
                  <template
                    v-if="
                      transfer.type === 1 ||
                      transfer.type === 3 ||
                      transfer.type === 5 ||
                      transfer?.swap_transaction?.type === 5 ||
                      transfer.type === 7 ||
                      transfer.type === 8
                    "
                  >
                    <div class="text-danger font-weight-semibold">{{ $t('Transaction.Output') }}:</div>
                    <div v-if="transfer.type === 1 || transfer.type === 7 || transfer.type === 8">
                      {{ transfer.account.name }}
                    </div>
                    <div v-else-if="transfer?.swap_transaction?.type === 5 || transfer.type === 5">
                      {{ transfer.swap_transaction.account.name }}
                    </div>
                  </template>

                  <template
                    v-if="
                      transfer.type === 0 ||
                      transfer.type === 2 ||
                      transfer.type === 4 ||
                      transfer.type === 6 ||
                      transfer.type === 9
                    "
                  >
                    <div class="text-success font-weight-semibold">{{ $t('Transaction.Input') }}:</div>
                    <div v-if="transfer.type === 0 || transfer.type === 6 || transfer.type === 9">
                      {{ transfer.account.name }}
                    </div>
                    <div v-else-if="transfer?.swap_transaction?.type === 4">
                      {{ transfer.swap_transaction.account.name }}
                    </div>
                  </template>
                </td>
                <!-- <td>
                  <div v-if="transfer.type === 1">
                    {{ transfer.account.name }}
                  </div>
                  <div v-else-if="transfer?.swap_transaction?.type === 1">
                    {{ transfer.swap_transaction.account.name }}
                  </div>
                </td> -->
                <!-- <td>
                  <div v-if="transfer.type === 0">
                    {{ transfer.account.name }}
                  </div>
                  <div v-else-if="transfer?.swap_transaction?.type === 0">
                    {{ transfer.swap_transaction.account.name }}
                  </div>
                </td> -->
                <td>
                  <h4 class="font-size-14 text-nowrap">
                    {{ transfer.amount }}
                    {{ transfer.currency?.symbol || transfer.currency?.key }}
                  </h4>
                </td>
                <td class="align-middle colum-status">
                  <div class="badge bg-success bg-soft font-size-10">{{ $t('Transaction.Completed') }}</div>
                </td>
              </tr>

              <tr v-if="!transfers.length">
                <td colspan="7" class="table__no-records py-4">{{ $t('Accounts.NoTransfers') }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<style scoped lang="scss">
th {
  font-weight: 400;
  color: #b9b9c3;
  border: none;
}

td {
  border: none;
}

.badge {
  width: 122px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
